import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Header from "../components/Header";
import Footer from "../components/Footer";

import "../../scss/main.scss";

export default function CTA() {
	return (
		<>
			<Helmet>
				<meta charset="UTF-8" />
				<meta http-equiv="X-UA-Compatible" content="IE=edge" />
				<link
					rel="shortcut icon"
					type="image/svg"
					href="./assets/marketing-site/images/favicon.svg"
				/>
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>Edyn Care | CTA</title>
			</Helmet>

			<div className="container">
				<Header />
				<main>
					<div className="cta-landing">
						<StaticImage placeholder="blurred"
							className="cta-left"
							src="../../static/assets/marketing-site/images/cta-pattern-01.png"
							objectFit='contain'
							loading="eager"
							placeholder="blurred"
						/>
						<div className="landing-content">
							<StaticImage placeholder="blurred"
								src="../../static/assets/marketing-site/images/cta-image-01.svg" 
								objectFit='contain'
								loading="eager"
								placeholder="blurred"
							/>
							<h1>Book your phone care consultation</h1>
							<p className="primary">
								Select a time to chat with one of our family care advisors to
								get started.
							</p>
						</div>
						<StaticImage placeholder="blurred"
							className="cta-right"
							src="../../static/assets/marketing-site/images/cta-pattern-02.png"
							objectFit='contain'
							loading="eager"
							placeholder="blurred"
						/>
					</div>

					<section>
						<div className="appointment-booking">
							<div className="appointment-details">
								<StaticImage placeholder="blurred"
									className="icon-default"
									src="../../static/assets/marketing-site/images/cta-form-icon01.svg"
									objectFit='contain'
									loading="eager"
									placeholder="blurred"
								/>
								<div className="details-number">
									<p className="secondary">
										If you’d rather, you can just call us now:
									</p>
									<Link to="tel:020 3970 9900">
										<h4 className="number"> 020 3970 9900</h4>
									</Link>
								</div>
							</div>

							<div className="appointment-form-frame">
								<form className="appointment-form" action="" method="GET">
									<h2>Enter your details to book an appointment</h2>
									<h2 className="mobile-heading">Next steps:</h2>

									<div className="input-block">
										<label>First name</label>
										<input
											className="default"
											type="text"
											id="fname"
											name="firstname"
											placeholder="Your first name"
										/>
									</div>

									<div className="input-block">
										<label>Email address</label>
										<input
											className="default"
											type="email"
											id="email"
											name="emailaddress"
											placeholder="Your email"
										/>
									</div>

									<div className="input-block">
										<label>Surname</label>
										<input
											className="default"
											type="text"
											id="surname"
											name="surname"
											placeholder="Your surname"
										/>
									</div>

									<div className="input-block">
										<label>Phone number</label>
										<input
											className="default"
											type="number"
											id="number"
											name="phonenumber"
											placeholder="Your phone number"
										/>
									</div>

									<input
										className="submit-button"
										type="submit"
										value="Select an appointment"
									/>

									<p className="secondary">
										‘By providing us with your name, phone number and email
										address, you consent to use contact you by email or phone to
										inform you of our services and other information we think
										will be of interest to you. You can withdraw your permission
										at any time by contacting us. <span className="underline">Privacy Policy.</span>'</p>
									
								</form>
							</div>
						</div>
					</section>

					<section>
						<div className="tp-divider cta">
							<div className="tp-left">
								<StaticImage placeholder="blurred" src="../../static/assets/marketing-site/images/cqc-approved.png" />
								<p className="secondary">
									Edyn Care is approved and regulated by the Care Quality
									commission
								</p>
							</div>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thegaurdian.png"
								alt="the Gaurdian"
								objectFit="contain"
							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/thetimes.png"
								alt="the times"
								objectFit="contain"
							/>
							<StaticImage placeholder="blurred"
								className="tp-img"
								src="../../static/assets/marketing-site/images/theeveningstandard.png"
								alt="evening standard"
								objectFit="contain"
							/>
						</div>
					</section>

					<section>
						<div className="next-steps-section">
							<h1>Next steps:</h1>
							<br />
							<div className="next-steps-content">
								<div className="infoblock">
									<h3>Care consultation</h3>
									<p className="primary">
										As well as advising on whether edyn is suitable, during this
										call we can offer expert advice on navigating the care
										sector.
									</p>
								</div>
								<div className="infoblock">
									<h3>Care assessment</h3>
									<p className="primary">
										A no-obligation care assessment from one of our care
										managers. They will learn what you’re hoping to achieve from
										your care, ensure your property is safe and will share a
										digital care plan of your goals.
									</p>
								</div>
								<div className="infoblock">
									<h3>Carer matching</h3>
									<p className="primary">
										We match your relative to professional carers on needs and
										personality, before sending over information about them
										through our online portal.
									</p>
								</div>
							</div>
						</div>
					</section>

					{/*   Trust pilot embed goes here  */}
					<section>
						<div className="trust pilot"> </div>
					</section>
				</main>
			</div>
			<Footer includeCTA={false} />
		</>
	);
}
